<section class="section feather-bg-img" style="background-image: url('assets/images/bg/bg-about.png');">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-8 text-center">
        <div class="text-center mb-5">
          <h1 class="mb-3">
            <h1 class="mb-3"> <span class="text-primary">{{'pisi.header-1' | translate}}</span>{{'pisi.header-2' |
              translate}}<span class="text-primary">{{'pisi.header-3' | translate}}</span> </h1>
          </h1>
          <hr style="width: 40%; border: 2px solid  #cf0e0e;">
          <p class="font-size-16">
            {{'pisi.sub' | translate}}
          </p>
        </div>
      </div>
    </div>
    <div class="mb-5 img-feature">
      <img src="assets/images/ss-app/home-pisi.png" class="d-block img-fluid">
    </div>
    <br>
    <div class="row">
      <div class="col-xl-3 text-center">
        <i class="mdi mdi-account-edit icon-feature-1"></i>
        <h6>{{'pisi.feature01' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-alarm icon-feature-2"></i>
        <h6>{{'pisi.feature02' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-bank icon-feature-4"></i>
        <h6>{{'pisi.feature03' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-database icon-feature-3"></i>
        <h6>{{'pisi.feature04' | translate}}</h6>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-xl-3 text-center">
        <i class="mdi mdi-calendar icon-feature-5"></i>
        <h6>{{'pisi.feature05' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-calendar-multiple icon-feature-6"></i>
        <h6>{{'pisi.feature06' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-calendar-check icon-feature-7"></i>
        <h6>{{'pisi.feature07' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-calendar-multiple-check icon-feature-8"></i>
        <h6>{{'pisi.feature08' | translate}}</h6>
      </div>
    </div>


    <div class="row mt-5">
      <div class="col-xl-3 text-center">
        <i class="mdi mdi-lan-connect icon-feature-1"></i>
        <h6>{{'pisi.feature09' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-account-convert icon-feature-4"></i>
        <h6>{{'pisi.feature10' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-notebook icon-feature-11"></i>
        <h6>{{'pisi.feature11' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-cellphone icon-feature-2"></i>
        <h6>{{'pisi.feature12' | translate}}</h6>
      </div>
    </div>
  </div>
</section>

<div class="container">
  <div class="row align-items-center justify-content-center">
    <div class="col-lg-8 text-center">
      <h4>{{'pisi.more' | translate}}</h4>
      <a href="https://pisicloud.com" target="_blank"><b>PISICLOUD.COM</b></a>
    </div>
  </div>
</div>