<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img-1.png)">
  <div class="container">
    <div class="text-center" *ngIf="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="text-center mt-5">
      <h3>{{blog.title}}</h3>
      <img [src]="blog.imgUrl" width="90%" class="mt-3">
    </div>

    <div [innerHTML]="blog.content" class="mode-pc mt-5"></div>
  </div>
</section>