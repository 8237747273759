<div class="section feather-bg-img" style="background-image: url(assets/images/bg/6.png)">
  <div class="container mt-5">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <h2 class="mb-3"> <span class="text-primary"> {{ 'client.h1-1' | translate}} </span> {{ 'client.h1-2' | translate}} <span class="text-primary"> {{ 'client.h1-3' | translate}} </span> </h2> 
        <p class="mb-5 pb-5 font-size-17">
          {{'client.p1' | translate}}
        </p>
       
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div class="mt-4 mt-lg-0">
          <img src="assets/images/customer.jpg" style="margin-top: -20px;" class="img-fluid d-block mx-auto">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Hero Start -->
<div class="hero-1-bg" style="background-image: url(assets/images/bg/6.png)" id="home">
  <div class="container" style="margin-top: -50px;">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h2> <b> <span class="text-primary">Inforsys</span> Customer</b></h2>
        </div>
        <hr style="border: 3px solid #CF0E0E; margin-top: -24px; width: 5em;">
      </div>
    </div>
    <div class="pc align-items-center mt-3">
      <div class="row mb-5 logo">
        <div class="col-md-2">
          <img src="assets/images/customer/1.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/2.jpg" style="width:60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/3.png" style="width:80%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/4.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/5.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/6.png">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/7.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/8.png" style="width:80%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/9.png" style="width: 40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/10.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/11.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/12.jpg" style="width:75%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/13.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/84.png" style="width:80%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/15.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/82.png" style="width:75%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/17.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/18.png" style="width:40%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/19.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/20.png" style="width:80%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/21.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/22.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/34.png" style="width: 60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/24.png" style="width: 40%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/25.png" style="width: 45%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/26.png" style="width: 40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/27.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/62.jpg" style="width: 65%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/29.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/30.jpg">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/31.png" style="width:55%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/33.jpg" style="width: 35%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/32.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/23.png" style="width: 40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/35.jpg" style="width: 60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/36.jpg" style="width: 60%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/37.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/38.jpg" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/39.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/83.png" style="width:80%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/41.png" style="width: 45%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/42.png">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/43.png" style="width:45%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/81.png" style="width:80%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/45.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/46.jpeg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/47.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/48.jpg">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/49.jpg" style="width:60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/50.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/51.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/52.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/53.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/54.png" style="width:60%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/55.jpg" style="width: 55%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/56.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/57.jpg" style="width:70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/58.jpeg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/59.png" style="width: 40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/60.png" style="width: 70%">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/61.jpg" style="width: 45%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/28.jpg">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/63.jpg" style="width: 75%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/64.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/79.png" style="width:50%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/66.png" style="width:35%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/67.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/40.jpg" style="width:50%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/69.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/70.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/71.png" style="width:90%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/72.png" style="width:45%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/73.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/74.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/75.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/76.png" style="width:80%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/77.png" style="width:60%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/85.png" style="width:70%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/65.png" style="width: 35%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/80.png" style="width: 70%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/44.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/16.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/68.png">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/14.png" style="width:40%;">
        </div>
      </div>

      <div class="row mb-5">
        <div class="col-md-2">
          <img src="assets/images/customer/86.png" style="width: 65%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/78.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/87.png" style="width:100%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/88.png" style="width:40%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/89.png" style="width:75%;">
        </div>
        <div class="col-md-2">
          <img src="assets/images/customer/90.png" style="width:70%;">
        </div>
      </div>
    </div>

    <div class="mobile align-items-center mt-3">
      <br><br>
      <div class="text-center mb-5">
        <img src="assets/images/customer/1.png" class="mr-5">
        <img src="assets/images/customer/2.jpg">
      </div>
      <div class="text-center mb-5">
        <img src="assets/images/customer/3.png" class="mr-5">
        <img src="assets/images/customer/4.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/5.png" class="mr-5">
        <img src="assets/images/customer/6.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/7.jpg" class="mr-5">
        <img src="assets/images/customer/8.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/9.png" class="mr-5">
        <img src="assets/images/customer/10.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/11.png" class="mr-5">
        <img src="assets/images/customer/12.jpg" style="width: 35%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/13.png" class="mr-5" style="width : 20%;">
        <img src="assets/images/customer/84.png" style="width: 32%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/15.png" class="mr-5">
        <img src="assets/images/customer/82.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/17.png" class="mr-5">
        <img src="assets/images/customer/18.png" style="width : 20%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/19.jpg" class="mr-5">
        <img src="assets/images/customer/20.png" style="width: 40%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/21.png" class="mr-5">
        <img src="assets/images/customer/22.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/23.png" class="mr-5">
        <img src="assets/images/customer/24.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/25.png" class="mr-5">
        <img src="assets/images/customer/26.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/27.png" class="mr-5">
        <img src="assets/images/customer/62.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/29.jpg" class="mr-5">
        <img src="assets/images/customer/30.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/31.png" class="mr-5">
        <img src="assets/images/customer/32.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/33.jpg" class="mr-5">
        <img src="assets/images/customer/34.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/35.jpg" class="mr-5">
        <img src="assets/images/customer/36.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/37.jpg" class="mr-5">
        <img src="assets/images/customer/38.jpg" style="width: 20%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/39.png" class="mr-5">
        <img src="assets/images/customer/83.png" style="width: 35%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/41.png" class="mr-5">
        <img src="assets/images/customer/42.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/43.png" class="mr-5">
        <img src="assets/images/customer/81.png" style="width: 35%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/45.jpg" class="mr-5">
        <img src="assets/images/customer/46.jpeg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/47.jpg" class="mr-5">
        <img src="assets/images/customer/48.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/49.jpg" class="mr-5">
        <img src="assets/images/customer/50.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/51.jpg" class="mr-5">
        <img src="assets/images/customer/52.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/53.png" class="mr-5">
        <img src="assets/images/customer/54.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/55.jpg" class="mr-5">
        <img src="assets/images/customer/56.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/57.jpg" class="mr-5">
        <img src="assets/images/customer/58.jpeg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/59.png" class="mr-5">
        <img src="assets/images/customer/60.png" style="width: 35%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/61.jpg" class="mr-5">
        <img src="assets/images/customer/28.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/63.jpg" class="mr-5" style="width: 35%;">
        <img src="assets/images/customer/64.png" style="width: 25%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/79.png" class="mr-5">
        <img src="assets/images/customer/66.png" style="width: 20%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/67.png" class="mr-5">
        <img src="assets/images/customer/40.jpg">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/69.png" class="mr-5">
        <img src="assets/images/customer/70.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/71.png" class="mr-5" style="width: 40%;">
        <img src="assets/images/customer/72.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/73.png" class="mr-5">
        <img src="assets/images/customer/74.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/75.png" class="mr-5">
        <img src="assets/images/customer/76.png" style="width: 35%;">
      </div>
      2
      <div class="text-center mb-5">
        <img src="assets/images/customer/77.png" class="mr-5">
        <img src="assets/images/customer/85.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/65.png" class="mr-5">
        <img src="assets/images/customer/44.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/80.png" class="mr-5">
        <img src="assets/images/customer/16.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/68.png" class="mr-5">
        <img src="assets/images/customer/14.png" style="width: 20%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/78.png" class="mr-5" style="width: 20%;">
        <img src="assets/images/customer/86.png">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/87.png" class="mr-5" style="width: 40%;">
        <img src="assets/images/customer/88.png" style="width: 20%;">
      </div>

      <div class="text-center mb-5">
        <img src="assets/images/customer/89.png" style="width: 40%;">
      </div>
    </div>

    <!-- <app-testimonial></app-testimonial> -->