import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntryListComponent } from './entry-list/entry-list.component';
import { PostComponent } from './post/post.component';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpLoaderFactory, SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

@NgModule({
  declarations: [EntryListComponent, PostComponent],
  imports: [
    CommonModule,
    ScrollToModule.forRoot(),
    NgbModalModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: EntryListComponent,
      },
      // setting up the router to use param
      { path: ':id/:slug', component: PostComponent, pathMatch: 'full' },
    ]),
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class BlogModule { }
