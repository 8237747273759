import { Injectable } from '@angular/core';
import { BlogModel } from '../pages/model/blog.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import firebase from 'firebase/app'

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  blogData: AngularFirestoreCollection<BlogModel>;

  constructor(private firestore: AngularFirestore) { }

  async getAll() {
    const snapshot = await firebase.firestore().collection('blog').get()
    const data = await snapshot.docs.map(doc => doc.data());
    return data;
  }

  getById(id) {
    return this.firestore.doc(`blog/${id}`).valueChanges();
  }

}
