<section class="hero-1-bg section feather-bg-img" style="background-image: url('assets/images/bg/bg-about.png');">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-8 text-center">
        <div class="text-center mb-5">
          <h1 class="mb-3">
            <h1 class="mb-3"> <span class="text-primary">{{'sofi.header-1' | translate}}</span>{{'sofi.header-2' |
              translate}}<span class="text-primary">{{'sofi.header-3' | translate}}</span> </h1>
          </h1>
          <hr style="width: 40%; border: 2px solid  #cf0e0e;">

          <p class="font-size-16 mb-5">
            {{'sofi.sub' | translate}}
          </p>
        </div>
      </div>
    </div>

    <div class="mb-5 img-feature">
      <img src="assets/images/ss-app/home-sofi.png" class="d-block img-fluid">
    </div>
    <br>
    <div class="row">
      <div class="col-xl-3 text-center">
        <i class="mdi mdi-clipboard icon-feature-1"></i>
        <h6>{{'sofi.feature01' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-book-multiple icon-feature-2"></i>
        <h6>{{'sofi.feature02' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-bank icon-feature-3"></i>
        <h6>{{'sofi.feature03' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-factory icon-feature-4"></i>
        <h6>{{'sofi.feature04' | translate}}</h6>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-xl-3 text-center">
        <i class="mdi mdi-currency-usd icon-feature-5"></i>
        <h6>{{'sofi.feature05' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-package-variant-closed icon-feature-6"></i>
        <h6>{{'sofi.feature06' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-chart-pie icon-feature-7"></i>
        <h6>{{'sofi.feature07' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-city icon-feature-8"></i>
        <h6>{{'sofi.feature08' | translate}}</h6>
      </div>
    </div>


    <div class="row mt-5">
      <div class="col-xl-3 text-center">
        <i class="mdi mdi-autorenew icon-feature-9"></i>
        <h6>{{'sofi.feature09' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-finance icon-feature-10"></i>
        <h6>{{'sofi.feature10' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-security icon-feature-11"></i>
        <h6>{{'sofi.feature11' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-cloud-check icon-feature-12"></i>
        <h6>{{'sofi.feature12' | translate}}</h6>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-xl-3 text-center">
        <img src="assets/images/ss-app/production.png" width="20%" class="mb-4">
        <h6>{{'sofi.feature13' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <img src="assets/images/ss-app/standard-coasting.png" width="20%" class="mb-4">
        <h6>{{'sofi.feature14' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <i class="mdi mdi-crane icon-feature-4"></i>
        <h6>{{'sofi.feature15' | translate}}</h6>
      </div>

      <div class="col-xl-3 text-center">
        <img src="assets/images/ss-app/project-budgeting.png" width="20%" class="mb-4">
        <h6>{{'sofi.feature16' | translate}}</h6>
      </div>
    </div>
  </div>
</section>

<div class="container">
  <div class="row align-items-center justify-content-center">
    <div class="col-lg-8 text-center">
      <h4> {{'sofi.more' | translate}} </h4>
      <a href="https://soficloud.com" target="_blank"><b>SOFICLOUD.COM</b></a>
    </div>
  </div>
</div>