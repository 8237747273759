import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

import { PagesRoutingModule } from './pages-routing.module';

import { HttpLoaderFactory, SharedModule } from '../shared/shared.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SoftwareImplementationComponent } from './software-implementation/software-implementation.component';
import { TrainingComponent } from './training/training.component';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CustomizeModuleComponent } from './customize-module/customize-module.component';
import { AboutComponent } from './about/about.component';
import { Covid19Component } from './covid19/covid19.component';
import { HomeComponent } from './home/home.component';
import { ClientsComponent } from './clients/clients.component';
import { ProductSoficloudComponent } from './product-soficloud/product-soficloud.component';
import { ProductPisicloudComponent } from './product-pisicloud/product-pisicloud.component';
import { ProductCustomizeComponent } from './product-customize/product-customize.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

@NgModule({
  declarations: [
    IndexComponent,
    HomeComponent,

    PrivacyComponent,
    TermsConditionsComponent,
    SoftwareImplementationComponent,
    TrainingComponent,
    AnnualMaintenanceComponent,
    CustomizeModuleComponent,
    AboutComponent,
    Covid19Component,
    ClientsComponent,
    ProductSoficloudComponent,
    ProductPisicloudComponent,
    ProductCustomizeComponent

  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    ScrollToModule.forRoot(),
    NgbModalModule,
    NgbModule,
    NgxYoutubePlayerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class PagesModule { }
