<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/5.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold mb-4 text-center">{{'privacy.title1' | translate}}<span class="text-primary">{{'privacy.title2' | translate}}</span></h1>
      <hr style="width: 20%; border: 2px solid #1565c0;">
      <br><br><br>
      <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'privacy.h1' | translate}}</b></h3> 
      <p>{{'privacy.h1p1' | translate}}</p> 
      <ol>
        <li>{{'privacy.h1l1' | translate}}</li>
        <li>{{'privacy.h1l2' | translate}}</li>
        <li>{{'privacy.h1l3' | translate}}</li>
     </ol> 
     <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'privacy.h2' | translate}}</b></h3> 
      <ol>
        <li>{{'privacy.h2l1' | translate}}</li>
        <li>{{'privacy.h2l2' | translate}}</li>
        <li>{{'privacy.h2l3' | translate}}</li>
        <li>{{'privacy.h2l4' | translate}}</li>
     </ol> 
    <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'privacy.h3' | translate}}</b></h3> 
     <p>{{'privacy.h3p1' | translate}}</p>   
     <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'privacy.h4' | translate}}</b></h3> 
     <ol>
       <li>{{'privacy.h4l1' | translate}}</li>
       <li>{{'privacy.h4l2' | translate}}</li>
       <li>{{'privacy.h4l3' | translate}}</li>
    </ol> 
    <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'privacy.h5' | translate}}</b></h3> 
      <p>{{'privacy.h5p1' | translate}}</p> 
    <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'privacy.h6' | translate}}</b></h3> 
      <p>{{'privacy.h6p1' | translate}}</p> 
      <p>{{'privacy.h6p2' | translate}}</p> 
      <p>{{'privacy.h6p3' | translate}}</p> 
      <p>{{'privacy.h6p4' | translate}}</p> 
    <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'privacy.h7' | translate}}</b></h3> 
      <p>{{'privacy.h7p1' | translate}}</p>   
    </div>
  </section>
  <!-- Hero End -->
</div>