import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BlogService } from 'src/app/services/blog.service';
import { BlogModel } from '../../model/blog.model';
import { entries, Post } from './entries';

@Component({
  selector: 'app-entry-list',
  templateUrl: './entry-list.component.html',
  styleUrls: ['./entry-list.component.scss'],
})
export class EntryListComponent implements OnInit {
  currentSection = 'blog';
  entries: Post[];
  blog: BlogModel = new BlogModel()
  blogData: BlogModel[] = []
  isLoading: boolean;
  savedLang = localStorage.getItem('lang');

  constructor(private blogService: BlogService, private translate: TranslateService) { }

  slugify(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  async ngOnInit(): Promise<void> {
    await this.getAllBlog();
    this.setLanguage()
  }

  private setLanguage() {
    this.translate.addLangs(['id', 'en', 'cn', 'jp', 'kr']);
    this.translate.setDefaultLang(!this.savedLang ? 'id' : this.savedLang);
  }

  onChangeLang(lang: string) {
    this.savedLang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

  async getAllBlog(): Promise<void> {
    this.isLoading = true;
    this.blogData = await new Promise(res => {
      this.blogService.getAll().then(async (blog: any) => {
        await blog.forEach(data => {
          var trimmedString = data.content.substr(0, 150);
          data.content = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + '...';
        });
        res(blog);
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      })
    });
  }

  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
}