<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Training & Re-Implementation</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted mb-5 pb-5 font-size-17">
              Pemahaman individu terhadap produk secara utuh dan detil di organisasi memegang peranan cukup penting di
              awal siklus implementasi software maupun untuk menjamin keberlangsungan penggunaan software.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_exams_g4ow.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <p class="text-muted font-size-15 mb-4">
        Disamping hal tersebut, pertukaran individu di dalam organisasi dan proses transfer knowledge yang tidak
        berjalan lancar menjadi kendala utama keberlangsungan penggunaan software.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Kami menyediakan layanan training ulang produk untuk fitur-fitur khusus yang dibutuhkan dan juga re-implementasi
        produk jika ternyata proses implementasi awal di perusahaan Anda menghadapi kegagalan.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Perusahaan Anda juga akan memerlukan re-implementasi ulang untuk software yang sama ketika dilakukan ekspansi
        bisnis dengan pembukaan cabang perusahaan baru ataupun pembentukan anak perusahaan. Kami akan melakukan
        duplikasi software untuk kemudian dilakukan implementasi ulang dengan SOP perusahaan yang sudah berjalan baik.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Dengan strategi implementasi yang baik dan penuh kedisiplinan, akan sangat menentukan kesuksesan re-implementasi
        dan mengurangi resiko kegagalan.
      </p>
    </div>
  </section>

  <!-- Hero End -->

</div>