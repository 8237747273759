<section class="section bg-light">
  <div class="container" style="margin-top: -4em; margin-bottom: -4em;">
    <div class="row text-center">
      <div class="col-xl-3">
        <i class="mdi mdi-calendar"></i>
        <p class="number counter counter-1">1998</p>
        <hr>
        <p class="small-title">{{'companyStatistic.since' | translate}}</p>
      </div>

      <div class="col-xl-3">
        <i class="mdi mdi-account-group"></i>
        <p class="number counter counter-2">32+</p>
        <hr>
        <p class="small-title">{{'companyStatistic.team' | translate}}</p>
      </div>

      <div class="col-xl-3">
        <i class="mdi mdi-city"></i>
        <p class="number counter counter-3">260+</p>
        <hr>
        <p class="small-title">{{'companyStatistic.customer' | translate}}</p>
      </div>

      <div class="col-xl-3">
        <i class="mdi mdi-account-supervisor"></i>
        <p class="number counter counter-4">3800+</p>
        <hr>
        <p class="small-title">{{'companyStatistic.user' | translate}}</p>
      </div>

    </div>
  </div>
</section>