import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  savedLang = localStorage.getItem('lang');

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.setLanguage()
  }

  private setLanguage() {
    this.translate.addLangs(['id', 'en', 'cn', 'jp', 'kr']);
    this.translate.setDefaultLang(!this.savedLang ? 'id' : this.savedLang);
  }

  onChangeLang(lang: string) {
    this.savedLang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    window.location.reload();
  }

}
