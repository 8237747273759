// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCWnUzctzKuAzwZtQPfI3QmV_WVWpp7CXU",
    authDomain: "inforsys-co-id.firebaseapp.com",
    databaseURL: "https://inforsys-co-id.firebaseio.com",
    projectId: "inforsys-co-id",
    storageBucket: "inforsys-co-id.appspot.com",
    messagingSenderId: "657404439750",
    appId: "1:657404439750:web:6feb3cf2e3eedc54",
    measurementId: "G-GYST0PG9D7"
  },
  recaptcha: {
    siteKey: '6LcO-CghAAAAAF1bTs8ZyPzjOIP_CCL2ga19emLW',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
