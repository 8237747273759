import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { BlogModel } from '../../model/blog.model';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PostComponent implements OnInit {
  currentSection = 'blog';
  isLoading: boolean;
  blog: BlogModel = new BlogModel()
  blogData: BlogModel[] = []

  constructor(private route: ActivatedRoute, private blogService: BlogService) { }

  async ngOnInit() {
    this.isLoading = true;
    const id: any = this.route.url
    this.blog = await new Promise(res => {
      this.blogService.getById(id._value[0].path).subscribe((_blog: any) => {
        res(_blog);
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      })
    });
  }

  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
}
