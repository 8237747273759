<app-header></app-header>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/bg-about.png)">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="text-center mb-5 mt-3">
        <div innerHTML="{{'blog.blogHeader' | translate}}"></div>
        <p style="font-size: 22px;">
          {{'blog.blogSub' | translate}}
        </p>
        <hr style="width: 30%; border: 2px solid  #cf0e0e;">
      </div>
    </div>

    <div class="text-center" *ngIf="isLoading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="row" *ngIf="!isLoading">
      <div class="col-lg-4 mb-5" *ngFor="let entry of blogData">
        <div class="card card-01" style="height: 500px;">
          <img [src]="entry.imgUrl" width="100%" class="card-img">
          <div class="card-body">
            <h4 class="card-title" style="margin-top: -20px;"><b>{{entry.title}}</b></h4>
            <small class="cat">
              <i class="mdi mdi-calendar-month-outline"></i> {{entry.last_update | date:'dd MMM, yyyy'}}
            </small>
            <div class="card-text" [innerHTML]="entry.content"></div>
            <div class="mt-2">
              <a href="/blog/{{entry.id}}/{{ entry.slug }}" class="text-primary font-weight-semibold">{{'blog.readMore'
                | translate}} </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <app-footer></app-footer> -->