<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/bg/7.1.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold mb-4 text-center">{{'termCondition.title1' | translate}}<span
          class="text-primary">{{'termCondition.title2' | translate}}</span>
      </h1>
      <hr style="width: 25%; border: 2px solid #1565c0;">
      <br><br><br>
      <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h1' | translate}}</b></h3> 
      <p>{{'termCondition.h1p1' | translate}}</p>
      <p>{{'termCondition.h1p2' | translate}}</p>
      <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h2' | translate}} </b></h3> 
      <p>{{'termCondition.h2p1' | translate}}</p> 
      <ol>
        <li>{{'termCondition.h2l1' | translate}}</li> 
        <li>{{'termCondition.h2l2' | translate}}</li> 
        <li>{{'termCondition.h2l3' | translate}}</li> 
        <li>{{'termCondition.h2l4' | translate}}</li> 
      </ol>     
     <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h3' | translate}}</b></h3> 
     <ol>
        <li>{{'termCondition.h3l1' | translate}}</li> 
        <li>{{'termCondition.h3l2' | translate}}</li> 
        <li>{{'termCondition.h3l3' | translate}}</li> 
        <li>{{'termCondition.h3l4' | translate}}</li> 
        <li>{{'termCondition.h3l5' | translate}}</li> 
        <li>{{'termCondition.h3l6' | translate}}</li> 
        <li>{{'termCondition.h3l7' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h4' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h4l1' | translate}}</li> 
      <li>{{'termCondition.h4l2' | translate}}</li> 
      <li>{{'termCondition.h4l3' | translate}}</li> 
      <li>{{'termCondition.h4l4' | translate}}</li> 
      <li>{{'termCondition.h4l5' | translate}}</li> 
      <li>{{'termCondition.h4l6' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h5' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h5l1' | translate}}</li> 
      <li>{{'termCondition.h5l2' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h6' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h6l1' | translate}}</li> 
      <li>{{'termCondition.h6l2' | translate}}</li> 
      <li>{{'termCondition.h6l3' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h7' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h7l1' | translate}}</li> 
      <li>{{'termCondition.h7l2' | translate}}</li> 
      <li>{{'termCondition.h7l3' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h8' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h8l1' | translate}}</li> 
      <li>{{'termCondition.h8l2' | translate}}</li> 
      <li>{{'termCondition.h8l3' | translate}}</li> 
      <li>{{'termCondition.h8l4' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h9' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h9l1' | translate}}</li> 
      <li>{{'termCondition.h9l2' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h10' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h10l1' | translate}}</li> 
      <li>{{'termCondition.h10l2' | translate}}</li> 
      <li>{{'termCondition.h10l3' | translate}}</li> 
      <li>{{'termCondition.h10l4' | translate}}</li> 
      <li>{{'termCondition.h10l5' | translate}}</li> 
    </ol>
    <h3 class=" mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h11' | translate}}</b></h3> 
    <ol>
      <li>{{'termCondition.h11l1' | translate}}</li> 
      <li>{{'termCondition.h11l2' | translate}}</li> 
      <li>{{'termCondition.h11l3' | translate}}</li> 
    </ol>
    <h3 class="mb-3"><b><i class="mdi mdi-chart-bubble h2 text-primary"> </i> {{'termCondition.h12' | translate}}</b></h3> 
    <p>{{'termCondition.h12p1' | translate}}</p>
    </div>
  </section>
  <!-- Hero End -->

</div>