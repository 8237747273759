<app-header></app-header>

<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Customize Module (Modifikasi Khusus)</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted mb-5 pb-5 font-size-17">
              Seiring pertumbuhan perusahaan menjadi lebih besar dan kompleks, kebutuhan pengembangan module software
              secara khusus juga sangat diperlukan.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_advanced_customization_58j6.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <p class="text-muted font-size-15 mb-4">
        Produk software yang baik tentunya produk yang adaptive terhadap perkembangan perusahaan dan kebutuhan
        khusus-nya. Pihak manajemen membutuhkan alat analisa lebih detil lagi atas transaksi bisnis yang terjadi untuk
        membantunya mengambil keputusan yang cepat dan tepat.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Kami menawarkan jasa pengembangan software kami secara khusus (customization module) untuk memenuhi kebutuhan
        perusahaan yang terus berkembang. Team kami akan membantu menganalisa kebutuhan dan solusi atas permasalahan
        yang terjadi.
      </p>
    </div>
  </section>
  <!-- Hero End -->

</div>