import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  sendEmail(data) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post('https://send-mail-hello-inforsys.herokuapp.com/send-email', data, { headers: headers });
  }

}
