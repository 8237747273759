import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  tokenRecaptcha: string;
  isSuccess: boolean;
  savedLang = localStorage.getItem('lang');

  constructor(private router: Router, private fb: FormBuilder, private emailService: EmailService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.setLanguage()
  }

  private setLanguage() {
    this.translate.addLangs(['id', 'en', 'cn', 'jp', 'kr']);
    this.translate.setDefaultLang(!this.savedLang ? 'id' : this.savedLang);
  }

  onChangeLang(lang: string) {
    this.savedLang = lang;
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
    window.location.reload();
  }


  contactForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]],
    compName: ['', [Validators.required]],
    compType: ['', [Validators.required]],
    position: ['', [Validators.required]],
    product: ['', [Validators.required]],
    message: ['', [Validators.required]]
  })

  get f() { return this.contactForm.controls; }

  sendMessage() {
    if (this.tokenRecaptcha) {
      const data = {
        message: `<html><body>
        <p>Nama :</p>
        <p><b>${this.contactForm.value.name}</b></p>
        <hr>
        <p>Email :</p>
        <p><b>${this.contactForm.value.email}</b></p>
        <hr>
        <p>No. Telp :</p>
        <p><b>${this.contactForm.value.phone}</b></p>
        <hr>
        <p>Nama Perusahaan :</p>
        <p><b>${this.contactForm.value.compName}</b></p>
        <hr>
        <p>Jenis Industri :</p>
        <p><b>${this.contactForm.value.compType}</b></p>
        <hr>
        <p>Jabatan di Perusahaan :</p>
        <p><b>${this.contactForm.value.position}</b></p>
        <hr>
        <p>Produk yang di Inginkan :</p>
        <p><b>${this.contactForm.value.product}</b></p>
        <hr>
        <p>Pesan :</p>
        <p><b>${this.contactForm.value.message}</b></p>
        <hr>
        </body></html>`
      }
      this.emailService.sendEmail(data).subscribe(res => {
        this.isSuccess = true;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/contact']);
        });
      }, err => {
        alert(err.error)
      });
    }
    else {
      alert('Recaptcha invalid');
    }
  }

  resolvedRecaptcha(token) {
    this.tokenRecaptcha = token;
  }

}
