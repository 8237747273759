import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SegmentComponent } from './segment/segment.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HeaderComponent } from './header/header.component';

import { FeatherModule } from 'angular-feather';
import {
  Mail, Link, PhoneCall, Clock, MapPin, Facebook, Twitter, Instagram, Linkedin, Send, Calendar, User, Server, Rss, Layout, LifeBuoy,
  ArrowRightCircle, PieChart, Triangle, Gitlab, MessageCircle, Play, CheckCircle
} from 'angular-feather/icons';
import { ScrollspyDirective } from './scrollspy.directive';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { CustomerSlideComponent } from './customer-slide/customer-slide.component';
import { DemoNowComponent } from './demo-now/demo-now.component';
import { CompanyStatisticsComponent } from './company-statistics/company-statistics.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const icons = {
  Mail, Link, PhoneCall, Clock, MapPin, Facebook, Twitter, Instagram, Linkedin, Send, Calendar, User, Server, Rss, Layout, LifeBuoy,
  ArrowRightCircle, PieChart, Triangle, Gitlab, MessageCircle, Play, CheckCircle
};

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    ContactComponent,
    FooterComponent,
    ScrollspyDirective,
    HeaderComponent,
    SegmentComponent,
    TestimonialComponent,
    CustomerSlideComponent,
    CompanyStatisticsComponent,
    DemoNowComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ScrollToModule.forRoot(),
    FeatherModule.pick(icons),
    NgbAlertModule,
    RecaptchaModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  // tslint:disable-next-line: max-line-length
  exports: [
    ContactComponent,
    FooterComponent,
    FeatherModule,
    ScrollspyDirective,
    HeaderComponent,
    SegmentComponent,
    TestimonialComponent,
    CustomerSlideComponent,
    CompanyStatisticsComponent,
    DemoNowComponent
  ]
})
export class SharedModule { }
