<!-- Loader -->
<!-- <div id="preloader" *ngIf="isLoading">
  <div id="status">
    <div class="spinner"></div>
  </div>
</div> -->
<button id="myBtn" type="button" class="btn btn-primary btn-sm" (click)="scrollToTop()" data-placement="left">
  <i class="mdi mdi-arrow-up"></i>
</button>

<app-header></app-header>


<div [@routeAnimations]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet"></router-outlet>
</div>

<br><br><br>

<app-customer-slide></app-customer-slide>

<br><br><br>

<!-- <app-company-statistics></app-company-statistics> -->

<br><br><br>

<app-demo-now></app-demo-now>


<app-footer></app-footer>