<section class="hero-1-bg" style="background-image: url(assets/images/bg/bg-about.png)" id="home">
  <div class="container">
    <div class="text-center mb-5">
      <h1 class="font-weight-bold mb-4"> <b> {{'about.title-1' | translate}} <span class="text-primary"> {{'about.title-2' | translate}} </span> {{'about.title-3' | translate}} </b> </h1>
      <hr style="border: 2px solid #CF0E0E; width: 30%; margin-top: -10px;">
    </div>
    <br>

    <div class="section feather-bg-img" id="features">
      <div class="container feather-bg-img" style="margin-top: -3em;">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <h3 class="line-height-1_4 mb-4"> <span class="text-primary"> {{'about.h1-1' | translate}} </span> {{'about.h1-2' | translate}} </h3>
            <p class="text-size-15">
              {{'about.p1' | translate}}
            </p>
            <p class="text-size-15">
              {{'about.p2' | translate}}
            </p>
          </div>
          <div class="col-lg-5 offset-lg-1">
            <div class="mb-4 mb-lg-0">
              <img src="assets/images/ss-app/about1.png" alt="" class="img-fluid d-block border-dark">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section style="background-image: url(assets/images/bg/bg-about.png)">
  <div class="container feather-bg-img" style="margin-bottom: 3em; margin-top: -5em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="mt-5 mb-5">
          <img src="assets/images/ss-app/about2.png" alt="" class="img-fluid d-block border-dark">
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <h3 class="line-height-1_4 mb-4"> <span class="text-primary"> {{'about.h3-1' | translate}} </span> {{'about.h3-2' | translate}} </h3>
        <p class="text-size-15">
          {{'about.p3' | translate}}
        </p>
        <p class="text-size-15">
          {{'about.p4' | translate}}
        </p>
        <p class="text-size-15">
          {{'about.p5' | translate}}
        </p>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/bg-about.png)">
  <div class="container" style="margin-top: -4em; margin-bottom: 5em;">
    <div class="row align-items-center">
      <div class="col-lg-6 mb-5">
        <h1 class="line-height-1_4 mb-4"> {{'about.visionHeader-1' | translate}} <span class="text-primary"> {{'about.visionHeader-2' | translate}} </span> </h1>
        <p class="font-size-20">
          {{'about.visionSub' | translate}}
        </p>
      </div>
      <div class="col-lg-5 offset-lg-1">
        <div>
          <img src="assets/images/ss-app/visi.png" class="img-fluid d-block border-dark">
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section feather-bg-img" style="background-image: url(assets/images/bg/bg-about.png)">
  <div class="container" style="margin-top: -10em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="mt-6">
          <img src="assets/images/ss-app/misi.png" class="img-fluid d-block border-dark">
        </div>
      </div>

      <div class="col-lg-6 offset-lg-1">
        <h1 class="line-height-1_4 mb-4"> {{'about.missionHeader-1' | translate}} <span class="text-primary"> {{'about.missionHeader-2' | translate}} </span> </h1>
        <div style="margin-left: -40px;">
          <ul>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                {{'about.mission1' | translate}}
              </p>
            </li>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                {{'about.mission2' | translate}}
              </p>
            </li>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                {{'about.mission3' | translate}}
              </p>
            </li>
            <li>
              <p class="mb-2 font-size-15">
                <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
                {{'about.mission4' | translate}}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<br><br>
<section style="background-image: url(assets/images/bg/bg-about.png)">
  <div class="container">
    <div class="row justify-content-center mb-5">
      <div class="col-xl-8 text-center mb-5">
        <div class="mb-5">
          <h2 class="mb-3"> <b> {{'about.corporate-1' | translate }} <span class="text-primary"> {{'about.corporate-2' | translate }} </span> </b> </h2>
          <hr style="border: 2px solid #CF0E0E; width: 140px; margin-bottom: 10px;">
        </div>
        <div class="row">
          <div class="col-xl-4 mb-3">
            <p class="mb-4" style="margin-left: -40px;">
              <img src="assets/icons/integrity.png" width="20%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>{{'about.value-1' | translate }}</b>
            </p>
          </div>
          <div class="col-xl-4 mb-3">
            <p class="mb-4">
              <img src="assets/icons/innovation.png" width="25%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>{{'about.value-2' | translate }}</b>
            </p>
          </div>
          <div class="col-xl-4">
            <p>
              <img src="assets/icons/teamwork.png" width="25%" style="margin-right: 10px; margin-bottom: 5px;">
              <b>{{'about.value-3' | translate }}</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 text-right">
            <p>
              <img src="assets/icons/communication.jpg" width="14%" style="margin-right: 10px; margin-bottom: 5px;">
              <span style="margin-right: 10px;"><b>{{'about.value-4' | translate }}</b></span>
            </p>
          </div>
          <div class="col-xl-6" style="margin-left: -30px;">
            <p>
              <img src="assets/icons/simplicity.png" width="17%">
              <b>{{'about.value-5' | translate }}</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-8 text-center mb-4">
        <h2> <b>{{'about.principleHeader-1' | translate}}<span class="text-primary"> {{'about.principleHeader-2' | translate}} </span>
          {{'about.principleHeader-3' | translate}}<span class="text-primary">{{'about.principleHeader-4' | translate}}</span> </b> </h2>
        
        <p class="font-size-15"> {{'about.principleHeaderSub1' | translate}}
        </p>
        <hr style="width: 20%; border: 2px solid #cf0e0e;">
      </div>
      <p class="font-size-15 text-center"> {{'about.principleHeaderSub2' | translate}}
      </p>
    </div>
    <br><br>

    <div class="row">
      <div class="col-lg-3 text-center">
        <p>
          <img src="assets/images/prinsip/prinsip1.png" width="32%" class="mb-3">
        </p>
        <h5 class="font-weight-bold">{{'about.principle1Head' | translate}}</h5>
        <p> {{'about.principle1' | translate}} </p>
      </div>

      <div class="col-lg-3 text-center">
        <p>
          <img src="assets/images/prinsip/prinsip2.png" width="37%">
        </p>
        <h5 class="font-weight-bold">{{'about.principle2Head' | translate}}</h5>
        <p> {{'about.principle2' | translate}} </p>
      </div>

      <div class="col-lg-3 text-center">
        <p>
          <img src="assets/images/prinsip/prinsip3.png" width="26%">
        </p>
        <h5 class="font-weight-bold">{{'about.principle3Head' | translate}}</h5>
        <p> {{'about.principle3' | translate}} </p>
      </div>

      <div class="col-lg-3 text-center">
        <p>
          <img src="assets/images/prinsip/prinsip4.png" width="40%">
        </p>
        <h5 class="font-weight-bold">{{'about.principle4Head' | translate}}</h5>
        <p> {{'about.principle4' | translate}} </p>
      </div>

    </div>
    <br>
    <div class="row justify-content-md-center">
      <div class="col-lg-3 text-center">
        <p>
          <img src="assets/images/prinsip/prinsip5.png" width="30%" class="mb-3">
        </p>
        <h5 class="font-weight-bold">{{'about.principle5Head' | translate}}</h5>
        <p> {{'about.principle5' | translate}} </p>
      </div>

      <div class="col-lg-3 text-center" style="margin-top: -18px;">
        <p>
          <img src="assets/images/prinsip/prinsip6.png" width="43%">
        </p>
        <h5 class="font-weight-bold">{{'about.principle6Head' | translate}}</h5>
        <p> {{'about.principle6' | translate}} </p>
      </div>

      <div class="col-lg-3 text-center">
        <p>
          <img src="assets/images/prinsip/prinsip7.png" width="30%" class="mb-3">
        </p>
        <h5 class="font-weight-bold">{{'about.principle7Head' | translate}}</h5>
        <p> {{'about.principle7' | translate}} </p>
      </div>
    </div>
  </div>
</section>
