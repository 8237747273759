<section class="six">
  <div class="container">
    <div class="row pc">
      <div class="col-xl-6" style="margin-top: -30px; margin-left: -30px;">
        <img src="assets/images/model.png" width="100px">
      </div>
      <div class="col-xl-5 mt-5">
        <h3 class="mb-3">
          <div innerHTML="{{'demo.demoHeader' | translate}}"></div>
        </h3>
        <p> {{'demo.demoHeaderSub' | translate}} </p>
        <p>
          <i-feather class="icon-xs icon mr-2 text-primary" name="phone-call"></i-feather> <span>+62 778 416
            0250</span>
        </p>
        <p>
          <i-feather class="icon-xs icon mr-2 text-primary" name="message-circle"></i-feather> <span>+62 811 7774
            744</span>
        </p>
        <p>
          <i-feather class="icon-xs icon mr-2 text-primary" name="mail"></i-feather>
          <span>hello@inforsys.co.id</span>
        </p>
        <button class="btn btn-primary btn-sm mt-4 mb-3" (click)="goToWA()">
          {{'demo.buttonDemo' | translate}}
        </button>
      </div>
    </div>


    <div class="row mobile">
      <div class="col-xl-6 mt-5">
        <div innerHTML="{{'demo.demoHeader' | translate}}"></div>
        <p> {{'demo.demoHeaderSub' | translate}} </p>
        <p>
          <i-feather class="icon-xs icon mr-2 text-primary" name="phone-call"></i-feather> <span>+62 778 416
            0250</span>
        </p>
        <p>
          <i-feather class="icon-xs icon mr-2 text-primary" name="message-circle"></i-feather> <span>+62 811 7774
            744</span>
        </p>
        <p>
          <i-feather class="icon-xs icon mr-2 text-primary" name="mail"></i-feather>
          <span>hello@inforsys.co.id</span>
        </p>
        <button class="btn btn-primary btn-sm mt-4 mb-3" (click)="goToWA()">
          {{'demo.buttonDemo' | translate}}
        </button>
      </div>
      <div class="col-xl-6 mt-5">
        <img src="assets/images/model.png">
      </div>
    </div>
  </div>
</section>