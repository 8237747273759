<section class="hero-1-bg section feather-bg-img" style="background-image: url('assets/images/bg/bg-about.png');">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-8 text-center">
          <div class="text-center mb-5">
            <h1 class="mb-3">
              <h1 class="mb-3"> <span class="text-primary">{{'customizeProject.header-1' | translate}}</span>{{'customizeProject.header-2' | translate}} </h1>
            </h1>
            <hr style="width: 40%; border: 2px solid  #cf0e0e;">
  
            <p class="font-size-16 mb-5">
              {{'customizeProject.p1' | translate}}
            </p>
            <p class="font-size-16 mb-5">
                {{'customizeProject.p2' | translate}}
            </p>
            <p class="font-size-16 mb-5">
                {{'customizeProject.p3' | translate}}
            </p>
            <p class="font-size-16 mb-5">
                {{'customizeProject.p4' | translate}}
            </p>
          </div>
        </div>
      </div>

    </div>
  </section>
