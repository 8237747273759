<div class="hero-1-bg" style="background-image: url('assets/images/bg/3.png');">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-10 text-center">
        <h1 class="text-dark mb-4"> <b>
            {{'home.header-1' | translate}}<span class="text-primary">{{'home.header-2' | translate}}</span> <br>
            {{'home.header-3' | translate}} <span class="text-primary">{{'home.header-4' |
              translate}}</span>{{'home.header-5' | translate}}
          </b>
        </h1>
        <p class="text-size-16"> {{'home.subHeader' | translate}}
        </p>
        <button type="button" class="btn btn-primary btn-sm mr-2 mb-3" style="width: 185px;" (click)="goToWA()">
          <img src="../../../assets/images/whatsapp.png" height="22px" />
          {{'home.contactUs' | translate}}
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm mb-3" routerLink="/contact">
          <i-feather class="icon-size-15 ml-1 icon mr-1" name="mail"></i-feather>
          {{'home.demo' | translate}}
        </button>
      </div>
    </div>
    <br><br>
    <div class="img-feature">
      <img src="assets/images/ss-app/home-inforsys.png" class="d-block img-fluid" height="60%">
    </div>
  </div>
</div>

<!-- Features Summary -->
<section class="section feather-bg-img" style="background-image: url(assets/images/features-bg-img.png)">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center">
          <h2 class="mb-3"> <b> <span class="text-primary">{{'home.header-p1-1' | translate}}</span>{{'home.header-p1-2'
              | translate}}<span class="text-primary">{{'home.header-p1-3' | translate}}</span>{{'home.header-p1-4' |
              translate}}</b></h2>
          <p>{{'home.p1' | translate}}</p>
          <hr style="width: 20%; border: 2px solid #cf0e0e;">
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container mt-2 feather-bg-img" style="margin-top: -3em;">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="mb-4 mb-lg-0">
          <img src="assets/images/ss-app/homee.png" alt="" class="img-fluid d-block border-dark">
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <h2 class="mb-3"> <b> <span class="text-primary">{{'home.header-p2-1' | translate}}</span>{{'home.header-p2-2' |
            translate}}<span class="text-primary">{{'home.header-p2-3' | translate}}</span>{{'home.header-p2-4' |
            translate}}</b></h2>
        <p class="font-size-15"> {{'home.p2' | translate}}</p>
      </div>
    </div>
  </div>
  <br>
  <div class="container mt-5 feather-bg-img" style="margin-top: -5em;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <h2 class="mb-3"> <b> <span class="text-primary">{{'home.header-p3-1' | translate}}</span>{{'home.header-p3-2' |
            translate}}<span class="text-primary">{{'home.header-p3-3' | translate}}</span>{{'home.header-p3-4' |
            translate}}</b></h2>
        <p class="font-size-15"> {{'home.p3' | translate}}</p>
      </div>
      <div class="col-lg-5 offset-lg-1" style="margin-top: -5em;">
        <div class="mt-5 mb-5">
          <img src="assets/images/ss-app/home1.png" alt="" class="img-fluid d-block border-dark">
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-5 feather-bg-img" style="margin-bottom: 5em;">
    <div class="row">
      <div class="col-lg-5">
        <div class="mt-4">
          <img src="assets/images/ss-app/home2.png" class="img-fluid d-block border-dark">
        </div>
      </div>
      <div class="col-lg-6 offset-lg-1">
        <h2 class="mb-3"> <b> <span class="text-primary">{{'home.header-p4-1' | translate}}</span>{{'home.header-p4-2' |
            translate}}<span class="text-primary">{{'home.header-p4-3' | translate}}</span>{{'home.header-p4-4' |
            translate}}</b></h2>
        <p class="font-size-15"> {{'home.p4' | translate}}</p>
      </div>
    </div>
  </div>
</section>
<br><br><br>
<section class="section feather-bg-img">
  <div class="container" style="margin-top: -6em;">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h2 class="mb-3"> <b> <span class="text-primary">{{'home.header-p5-1' | translate}}</span>{{'home.header-p5-2'
              | translate}}<span class="text-primary">{{'home.header-p5-3' | translate}}</span></b></h2>
          <p class="font-size-15"> {{'home.p5' | translate}}</p>

          <hr style="width: 20%; border: 2px solid #cf0e0e;">
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <div class="pricing-box rounded text-center p-4" style="min-height: 100%;">
            <img src="assets/images/product/pisi.png" width="100%" style="margin-bottom: 30px;">
            <img src="assets/images/product/pisi-cloud-logo.png" width="63%" class="mb-3">
            <div class="line margin mb-4"></div>
            <div>
              <p class="mb-3"> {{'home.pisi' | translate}}
              </p>
            </div>
            <div class="line margin mb-4"></div>
            <p class=" font-size-16 mb-2">
              <b> {{'home.learnMore' | translate}} </b>
            </p>
            <p>
              <a href="https://pisicloud.com" target="_blank">PISICLOUD.COM</a>
            </p>
            <br>
            <div class="mt-4">
              <div class="hero-bottom-img">
                <img src="assets/images/pricing-bottom-bg.png" class="img-fluid d-block mx-auto">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="pricing-box rounded text-center p-4" style="min-height: 100%;">
            <img src="assets/images/product/sofi.png" width="100%" class="mb-5">
            <img src="assets/images/product/sofi-cloud-logo.png" width="75%" class="mb-3">
            <div class="line margin mb-4"></div>
            <div>
              <p> {{'home.sofi' | translate}}
              </p>
            </div>

            <div class="line margin mb-4"></div>
            <p class="font-size-16 mb-2">
              <b> {{'home.learnMore' | translate}} </b>
            </p>
            <p>
              <a href="https://soficloud.com" target="_blank">SOFICLOUD.COM</a>
            </p>
            <br>
            <div class="mt-4">
              <div class="hero-bottom-img">
                <img src="assets/images/pricing-bottom-bg.png" class="img-fluid d-block mx-auto">
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="pricing-box rounded text-center p-4" style="min-height: 100%;">
            <img src="assets/images/product/customize.jpg" width="100%" class="mb-4">
            <img src="assets/images/product/customize-logo.png" width="100%" class="mb-3 mr-3">
            <div class="line margin mb-4"></div>
            <div>
              <p class="font-size-16"> {{'home.customize' | translate}}
              </p>
            </div>
            <div class="line margin mb-4"></div>
            <p>
              <a href="/product-customize" class="text-primary font-weight-semibold"> {{'home.learnMoreCustomize' |
                translate}} <span class="ml-2 right-icon">&#8594;</span></a>
            </p>
            <br>
            <div class="mt-4">
              <div class="hero-bottom-img">
                <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Features End -->

<!-- Keunggulan Start -->
<div class="container mt-5 feather-bg-img">
  <div class="row align-items-center">
    <div class="col-lg-5">
      <div class="mt-2 mb-5">
        <img src="assets/images/ss-app/keunggulan.jpeg" alt="" class="img-fluid d-block border-dark" width="80%">
      </div>
    </div>
    <div class="col-lg-5 offset-lg-1 mb-2">
      <h3 class="line-height-1_4 mb-4"><i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> <b>
          <span class="text-primary">{{'home.whyUsTitle01' | translate}}</span>{{'home.whyUsTitle02' | translate}}<span
            class="text-primary">{{'home.whyUsTitle03' | translate}}</span>?</b> </h3>
      <p>{{'home.whyUsSub' | translate}}</p>
      <ul>
        <li>
          <p>
            <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
            <strong>{{'home.whyUs01Header' | translate}}</strong> {{'home.whyUs01' | translate}}
          </p>
        </li>
        <li>
          <p>
            <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
            <strong>{{'home.whyUs02Header' | translate}}</strong> {{'home.whyUs02' | translate}}
          </p>
        </li>
        <li>
          <p>
            <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
            <strong>{{'home.whyUs03Header' | translate}}</strong> {{'home.whyUs03' | translate}}
          </p>
        </li>
        <li>
          <p>
            <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
            <strong>{{'home.whyUs04Header' | translate}}</strong> {{'home.whyUs04' | translate}}
          </p>
        </li>
        <li>
          <p>
            <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
            <strong>{{'home.whyUs05Header' | translate}}</strong> {{'home.whyUs05' | translate}}
          </p>
        </li>
        <li>
          <p>
            <i-feather class="icon-xs mr-1 text-primary" name="check-circle"></i-feather>
            <strong>{{'home.whyUs06Header' | translate}}</strong> {{'home.whyUs06' | translate}}
          </p>
        </li>
      </ul>
      <div class="mt-5">
        <a href="/about" class="text-primary font-weight-semibold"> {{'home.readMore' | translate}} <span
            class="ml-2 right-icon">&#8594;</span></a>
      </div>
    </div>
  </div>
</div>
<!-- Keunggulan End -->
<br><br>

<!-- <app-testimonial></app-testimonial> -->

<br><br>
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item"
            src="https://www.youtube.com/embed/Jfrjeg26Cwk?autoplay=1&amp;modestbranding=1&amp;showinfo=0" id="video"
            allowscriptaccess="always"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>